<template>
  <div>
    <div class="mt-3">
      <b-row>
        <b-col md="6">
          <form
            ref="form"
            @submit.stop.prevent="handleEdit"
          >
            <b-form-group
              label="Document Number (UZ)"
              label-for="name-input"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name-input"
                v-model="nameState.number_uz"

                required
                size="sm"
              />
            </b-form-group>
          </form>
          <form
            ref="form"
            @submit.stop.prevent="handleEdit"
          >
            <b-form-group
              label="Document Number (UZ)"
              label-for="name-input"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name-input"
                v-model="nameState.number_ru"

                required
                size="sm"
              />
            </b-form-group>
            <b-form-group
              label="Document Number (EN)"
              label-for="name-input"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name-input"
                v-model="nameState.number_en"

                required
                size="sm"
              />
            </b-form-group>
            <b-form-group
              label="Document Number (EN)"
              label-for="name-input"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name-input"
                v-model="nameState.name_uz"

                required
                size="lg"
              />
            </b-form-group>
            <b-form-group
              label="Document Number (EN)"
              label-for="name-input"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name-input"
                v-model="nameState.name_ru"

                required
                size="lg"
              />
            </b-form-group>
            <b-form-group
              label="Document Number (EN)"
              label-for="name-input"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name-input"
                v-model="nameState.name_en"

                required
                size="lg"
              />
            </b-form-group>
          </form>
        </b-col>
        <b-col md="6">
          <div class="pt-2">
            <b-form-datepicker
              v-model="nameState.register_date"

              :min="min"
              :max="max"
              locale="en"
            />
          </div>
          <b-form-select
            v-model="nameState.year_id"
            :options="years"
            text-field="year"
            value-field="id"
            class="mt-2"
          />
          <b-form-select
            v-model="nameState.category_id"
            :options="categories"
            text-field="name_uz"
            value-field="id"
            class="mt-2"
          />
        </b-col>
      </b-row>
      <div class="text-right mt-3">
        <b-button
          variant="danger"
          class="mr-2"
          @click="closeEditModal"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="success"
          @click="handleEdit"
        >
          {{ $t('Edit') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { editDoc, getCategories, getYears } from '@/api/directories'

export default {
  name: 'EditDoc',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    docs: {
      default: null,
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 2)
    minDate.setDate(15)
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 2)
    maxDate.setDate(15)
    return {
      modalActive: false,
      nameState: {
        year_id: '',
        number_uz: '',
        number_ru: '',
        number_en: '',
        name_uz: '',
        name_ru: '',
        name_en: '',
        register_date: null,
        cur_status_id: 1,
        category_id: '',
      },
      years: [],
      categories: [],
      min: minDate,
      max: maxDate,
      selected: null,
    }
  },
  watch: {
    docs: {
      immediate: true,
      handler(val) {
        this.nameState = val
      },
    },
  },
  mounted() {
    getYears().then(res => {
      this.years = res.data.data
    })
    getCategories().then(res => {
      this.categories = res.data.data
    })
  },
  methods: {
    handleEdit() {
      if (this.nameState.year_id
              && this.nameState.number_uz
              && this.nameState.number_ru
              && this.nameState.number_en
              && this.nameState.name_uz
              && this.nameState.name_ru
              && this.nameState.name_en
              && this.nameState.cur_status_id
              && this.nameState.register_date
              && this.nameState.category_id) {
        editDoc(this.nameState).then(res => {
          this.$emit('editDoc')
        })
      }
    },
    closeEditModal() {
      this.$emit('closeEditModal')
    },
  },
}
</script>

  <style scoped>

  </style>
