<template>
  <section class="news-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <validation-observer ref="observer" class="w-100">
      <b-row>
        <b-col cols="6">
          <div class="w-100">
            <b-form-group :label="$t('Category')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Category')"
                  rules="required"
              >
                <v-select :options="categories" label="name" v-model="form.category" @option:selected="categorySelected"></v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="w-100">
            <b-form-group :label="$t('SubCategory')">
                <v-select :options="subcategories" label="name" v-model="form.subcategory" :disabled="!subcategories.length"></v-select>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="w-100">
            <b-form-group :label="$t('Year')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Year')"
                  rules="required"
              >
                <v-select :options="years" label="year" v-model="form.year"></v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="w-100">
            <b-form-group :label="$t('RegisterDate')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('RegisterDate')"
                  rules="required"
              >
                <input type="date" class="form-control" v-model="form.register_date">
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-tabs content-class="pt-1" fill>
        <b-tab title="Uz">
          <div class="w-100">
            <b-form-group :label="$t('NumberUz')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('NumberUz')"
                  rules="required"
              >
                <b-input v-model="form.number_uz" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-form-group :label="$t('TitleUz')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('TitleUz')"
                  rules="required"
              >
                <b-form-textarea v-model="form.name_uz">
                </b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab title="Ru">
          <div class="w-100">
            <b-form-group :label="$t('NumberRU')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('NumberRU')"
                  rules="required"
              >
                <b-input v-model="form.number_ru" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-form-group :label="$t('TitleRu')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('TitleRu')"
                  rules="required"
              >
                <b-form-textarea v-model="form.name_ru">
                </b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab title="En">
          <div class="w-100">
            <b-form-group :label="$t('NumberEn')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('NumberEn')"
                  rules="required"
              >
                <b-input v-model="form.number_en" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-form-group :label="$t('TitleEn')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('TitleEn')"
                  rules="required"
              >
                <b-form-textarea v-model="form.name_en">
                </b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
      <div class="d-flex justify-content-between">
        <div class="w-50">
          <input type="file" @change="getDocumentFile">
        </div>
        <div class="w-50">
          <input type="file" @change="getDocument2File">
        </div>
      </div>
      <div class="w-100 mt-4">
        <input type="file" @change="getDocument3File">
      </div>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="success" @click="saveSubmit" >{{$t('Add')}}</b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  addDoc, getDoc, editDoc, getCategories,
} from '@/api/documents'
import { getYears } from '@/api/directories'
import { formTemplate } from '@/utils/mixins/formTemplate'
import vSelect from 'vue-select'
import _ from 'lodash'

function initForm(data) {
  const get = (key, value = null) => _.get(data, key, value)

  return {
    id: get('id', null),
    category: get('category', null),
    subcategory: get('subcategory', null),
    year: get('year', null),
    number_uz: get('number_uz', null),
    number_ru: get('number_ru', null),
    number_en: get('number_en', null),
    name_uz: get('name_uz', null),
    name_ru: get('name_ru', null),
    name_en: get('name_en', null),
    register_date: get('register_date', null),
    file: get('file', null),
    file2: get('file2', null),
    file3: get('file3', null),
  }
}

const actions = {
  add: addDoc,
  update: editDoc,
  show: getDoc,
}

export default {
  name: 'DocumentForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [
    formTemplate,
  ],
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) return this.fetchData()

        this.setForm()
      },
    },
  },
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      categories: [],
      years: [],
      subcategories: [],
    }
  },
  created() {
    this.fetchCategories()
    this.fetchYears()
  },
  methods: {
    reformatData() {
      this.form.category_id = this.form.category.id
      this.form.sub_category_id = this.form.subcategory ? this.form.subcategory.id : null
      this.form.year_id = this.form.year.id
      const form = this.prepareFormData(this.form)
      return form
    },
    setForm(data) {
      this.form = initForm(data)
    },
    fetchCategories() {
      getCategories().then(res => {
        this.categories = res.data.data
      })
    },
    fetchYears() {
      getYears().then(res => {
        this.years = res.data.data
      })
    },
    categorySelected(item) {
      this.subcategories = item.children
      this.form.subcategory = null;
    },
    fetchData() {
      this.$store.dispatch('settings/setLoading', true)
      this.actions.show({ id: this.id }).then(({ data }) => {
        const editData = data.data ? data.data : data
        this.setForm(editData)
        this.subcategories = editData.category.children
      }).catch(err => {
        console.log(err)
      }).finally(() => this.$store.dispatch('settings/setLoading', false))
    },
    getDocumentFile(e) {
      this.form.file = e.target.files[0]
    },
    getDocument2File(e) {
      this.form.file2 = e.target.files[0]
    },
    getDocument3File(e) {
      this.form.file3 = e.target.files[0]
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/quill';
</style>
