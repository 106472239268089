var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"news-form"},[_c('b-overlay',{attrs:{"show":_vm.loading,"no-wrap":"","spinner-variant":"primary"}}),_c('validation-observer',{ref:"observer",staticClass:"w-100"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('Category')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.categories,"label":"name"},on:{"option:selected":_vm.categorySelected},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('SubCategory')}},[_c('v-select',{attrs:{"options":_vm.subcategories,"label":"name","disabled":!_vm.subcategories.length},model:{value:(_vm.form.subcategory),callback:function ($$v) {_vm.$set(_vm.form, "subcategory", $$v)},expression:"form.subcategory"}})],1)],1)]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('Year')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Year'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.years,"label":"year"},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('RegisterDate')}},[_c('validation-provider',{attrs:{"name":_vm.$t('RegisterDate'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.register_date),expression:"form.register_date"}],staticClass:"form-control",attrs:{"type":"date"},domProps:{"value":(_vm.form.register_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "register_date", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])],1),_c('b-tabs',{attrs:{"content-class":"pt-1","fill":""}},[_c('b-tab',{attrs:{"title":"Uz"}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('NumberUz')}},[_c('validation-provider',{attrs:{"name":_vm.$t('NumberUz'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.number_uz),callback:function ($$v) {_vm.$set(_vm.form, "number_uz", $$v)},expression:"form.number_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('TitleUz')}},[_c('validation-provider',{attrs:{"name":_vm.$t('TitleUz'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-tab',{attrs:{"title":"Ru"}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('NumberRU')}},[_c('validation-provider',{attrs:{"name":_vm.$t('NumberRU'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.number_ru),callback:function ($$v) {_vm.$set(_vm.form, "number_ru", $$v)},expression:"form.number_ru"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('TitleRu')}},[_c('validation-provider',{attrs:{"name":_vm.$t('TitleRu'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.form.name_ru),callback:function ($$v) {_vm.$set(_vm.form, "name_ru", $$v)},expression:"form.name_ru"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-tab',{attrs:{"title":"En"}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('NumberEn')}},[_c('validation-provider',{attrs:{"name":_vm.$t('NumberEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.number_en),callback:function ($$v) {_vm.$set(_vm.form, "number_en", $$v)},expression:"form.number_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('TitleEn')}},[_c('validation-provider',{attrs:{"name":_vm.$t('TitleEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.form.name_en),callback:function ($$v) {_vm.$set(_vm.form, "name_en", $$v)},expression:"form.name_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"w-50"},[_c('input',{attrs:{"type":"file"},on:{"change":_vm.getDocumentFile}})]),_c('div',{staticClass:"w-50"},[_c('input',{attrs:{"type":"file"},on:{"change":_vm.getDocument2File}})])]),_c('div',{staticClass:"w-100 mt-4"},[_c('input',{attrs:{"type":"file"},on:{"change":_vm.getDocument3File}})])],1),_c('div',{staticClass:"w-100 mt-3 d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.saveSubmit}},[_vm._v(_vm._s(_vm.$t('Add')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }