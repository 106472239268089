<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="2">
          <div class="input-group">
            <b-input
                :placeholder="$t('Search')"
                v-model="filters.search"
            ></b-input>
          </div>
        </b-col>
        <b-col md="3">
          <v-select
              :placeholder="$t('Category')"
              :options="categories"
              label="name"
              v-model="category"
              name="document_category"
          ></v-select>
        </b-col>
        <b-col md="2">
          <v-select
              :placeholder="$t('Year')"
              :options="years"
              label="year"
              v-model="year"
              name="document_category"
          ></v-select>
        </b-col>
        <b-col md="2">
          <v-select
              :placeholder="$t('Status')"
              :options="statuses"
              label="name"
              v-model="status"
              name="status"
          ></v-select>
        </b-col>
        <b-col md="1">
          <b-button variant="success" @click="getSearch">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-col>
        <b-col></b-col>
        <b-col md="1">
          <div class="w-100 d-flex justify-content-end">
            <b-button variant="primary" @click="handleAdd">{{
                $t("Add")
              }}</b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div class="w-100 mt-2">
      <b-overlay id="overlay-background" :show="showTableOverlay" rounded="sm">
        <b-card>
          <b-table striped hover :items="datas" :fields="fields" responsive="">
            <template #cell(id)="data">
              <span class="text-md text-primary">
                {{ data.index + 1 + pagination.pageSize * (pagination.page - 1) }}</span
              >
            </template>
            <template #cell(register_date)="{item}">
              <span class="d-flex justify-content-center mt-1">
                <span>
                  {{ item.register_date | dateFormat }}
                </span>
                <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
              </span>
            </template>
            <template #cell(category)="{item}">
              {{item.category ? item.category.name : ''}} <br>
              <span class="text-secondary">{{item.subcategory ? item.subcategory.name : ''}}</span>
            </template>
            <template #cell(cur_status)="{item}">
              <span>
                <strong>
                  {{item.cur_status.name}}
                </strong>
              </span>
            </template>
            <template #cell(action)="data">
              <div class="d-flex">
                <b-button
                    size="sm"
                    class="mr-1"
                    variant="primary"
                    @click="handlePush(data.item.id)"
                >
                  <b-icon icon="eye" />
                </b-button>
                <b-button
                    size="sm"
                    class="mr-1"
                    variant="warning"
                    @click="handleEdit(data.item.id)"
                >
                  <b-icon icon="pencil" />
                </b-button>
                <b-button
                    class="mr-1"
                    size="sm"
                    variant="danger"
                    @click="handleDelete(data.item.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
                <a v-if="data.item.file_path" target="_blank" :href="`${backendPath.backendPath}${data.item.file_path}`">
                  <b-button size="sm" variant="info">
                    <b-icon icon="download"></b-icon>
                  </b-button>
                </a>
                <a v-if="data.item.file2_path" target="_blank" :href="`${backendPath.backendPath}${data.item.file2_path}`" class="ml-1">
                  <b-button size="sm" variant="warning">
                    <b-icon icon="download"></b-icon>
                  </b-button>
                </a>
                <a v-if="data.item.file3_path" target="_blank" :href="`${backendPath.backendPath}${data.item.file3_path}`" class="ml-1">
                  <b-button size="sm" variant="success">
                    <b-icon icon="download"></b-icon>
                  </b-button>
                </a>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-overlay>
    </div>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      v-model="modalVisible"
      :title="modalTitle"
      hide-footer
      size="lg"
    >
      <DocumentForm :id="id" @saved="fetchList" />
    </b-modal>
    <div class="overflow-auto text-center">
      <b-pagination
        v-model="pagination.page"
        align="center"
        :total-rows="total"
        :per-page="pagination.pageSize"
        first-number
        size="lg"
        class="pt-2"
        @input="fetchList"
      />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import DocumentForm from "@/views/loginpages/moderator/documents/DocumentForm";
import { deleteDoc, getDocs, getCategories } from "@/api/documents";
import { getYears, getStatuses } from "@/api/directories";
// eslint-disable-next-line import/extensions
import EditDoc from "@/views/loginpages/moderator/components/docaction/doctypes/EditDoc";
import { listTemplate } from "@/utils/mixins/listTemplate";
import vSelect from "vue-select";
import backendPath from '../../../../libs/vue-axios.config'

const actions = {
  get: getDocs,
  delete: deleteDoc,
};

export default {
  name: "Index",
  // eslint-disable-next-line vue/no-unused-components
  components: { EditDoc, DocumentForm, vSelect },
  mixins: [listTemplate],
  data() {
    return {
      actions,
      backendPath,
      rows: 0,
      currentPage: 1,
      perPage: 0,
      docs: [],
      modalActive: false,
      activeDocs: null,
      categories: [],
      years: [],
      statuses: [],
      filters: {
        search: "",
        category_id: null,
        year_id: null,
        status_id: null,
      },
      category: null,
      year: null,
      status: null,
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        {
          key: "number",
          label: this.$t("Number"),
        },
        {
          key: "name",
          label: 'Название',
        },
        {
          key: "category",
          label: this.$t('Category'),
        },
        {
          key: "year.year",
          label: this.$t("Year"),
        },
        {
          key: "register_date",
          label: this.$t("Register_Date"),
        },
        {
          key: 'cur_status',
          label: this.$t("Current_status"),
        },
        {
          key: "action",
          label: this.$t("Action"),
        },
      ],
      showTableOverlay: false,
    };
  },
  mounted() {
    this.fetchYears();
    this.fetchCategories();
    this.fetchStatuses();
  },
  methods: {
    handlePush(id) {
      this.$router.push({ name: "Paragraph", params: { id } });
    },
    fetchCategories() {
      getCategories().then((res) => {
        this.categories = res.data.data;
      });
    },
    fetchYears() {
      getYears().then((res) => {
        this.years = res.data.data;
      });
    },
    fetchStatuses() {
      getStatuses().then((res) => {
        this.statuses = res.data.data;
      });
    },
    getSearch() {
      if (this.category) {
        this.filters.category_id = this.category.id;
      }
      else {
        this.filters.category_id = null
      }
      if (this.year) {
        this.filters.year_id = this.year.id;
      } else {
        this.filters.year_id = null
      }
      if (this.status) {
        this.filters.status_id = this.status.id;
      } else {
        this.filters.status_id = null
      }
      this.fetchList();
    },
  },
};
</script>

<style scoped></style>
